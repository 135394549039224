import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

const Svg = (props) => {

  const [content, setContent] = useState(null);

  useEffect(() => {

    axios.get(props.path).then(response => {
      if (!Array.isArray(response.data)) {
        setContent(parse(response.data));
      }
    });

  }, []);

  return (<>{content}</>);
}

export default Svg;