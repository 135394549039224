export const fr = {

  "banner.text1": "Plus de {oilNb} huiles d'olive AOP",
  "banner.text2": "{oilNb} huiles d'olive en AOP",

  "card.since": "AOP DEPUIS",
  "card.prod.aop": "Production d'huile en AOP",
  "card.varieties": "Variétés principales pour l'huile d'olive en AOP",
  "card.mills": "Moulins",
  "card.municipalities": "Communes",
  "card.olive.growers": "Oléiculteurs",
  "card.typology": "Typologie de l'huile d'olive",
  
  "card.orchards": "Vergers plantés d'oliviers",
  "card.prod.national": "Production nationale moyenne",
  "card.oil": "huiles d'olive en AOP",
  "card.part": "Part de la production en AOP dans la production nationale",
  "card.aop.list": "Liste des AOP",
};
