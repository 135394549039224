/**
 * @summary Effectue un 'require()' sur : l'emplacement des medias + nom du media 
 * @param {*} media nom du fichier media
 * @returns media
 */
export function loadMedia(media) {
  try {
    if (media && !isURL(media)) return require("../../assets/medias/" + media);
  } catch (e) {
    if (process.env.NODE_ENV !== "production") {
      console.error("File not found ", media, e);
    }
  }
  return media;
}

/**
 * @summary Vérifie si la chaine de caractère donnée est une URL (=> commence par http)
 * @param {*} string
 * @returns boolean
 */
export function isURL(string) {
  return string.startsWith("http");
}
