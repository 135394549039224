import React from "react";
import * as DataManager from "../../modules/datamanager/";
import { loadMedia } from "../../modules/utils";
import Arbre from "../../assets/images/arbre.svg";
import Goutte from "../../assets/images/goutte_huile.svg";
import LogoAOP from "../../assets/images/logo_aop_2.svg";
import Olives from "../../assets/images/olives.svg";
import Moulin from "../../assets/images/moulin.svg";
import Oleiculteurs from "../../assets/images/oleiculteur.svg";
import Communes from "../../assets/images/commune.svg";
import CloseBtn from "../../assets/images/close.svg";

export function Card(props) {

  const locale = props.locale;
  const formatMessage = props.formatMessage;
  const carteId = props.carteId;
  const zone = DataManager.getZoneById(props.zoneId);
  const card = carteId === 1 ? "Europe" : "Provence";
  const closeCard = props.closeHandler;

  return (
    <div className="card-container" onClick={closeCard}>
      {card === "Europe" ?
        <div
          className="card europe-card"
          style={{ top: props.top, left: props.left }}
        >
          <img src={CloseBtn} alt="close" id="close" onClick={closeCard} />
          <div className="card-title bottom-separator">
            <img src={loadMedia(zone.logo)} alt={`Drapeau ${zone.name.fr}`} />
            <h2 className="card-header">{zone.name[locale]}</h2>
          </div>
          <div className="informations-1 bottom-separator">
            <div>
              <img src={Arbre} alt="olivier" />
              <div>
                <h3>{zone.informations.orchardsNb} ha</h3>
                <p>{formatMessage({ id: "card.orchards" })}</p>
              </div>
            </div>
            <div>
              <img src={Goutte} alt="goutte" className="goutte" />
              <div>
                <h3>{zone.informations.production} t</h3>
                <p>{formatMessage({ id: "card.prod.national" })}</p>
              </div>
            </div>
            <div className="nb-aop">
              <img src={LogoAOP} alt="logo AOP" />
              <div>
                <h3>{zone.informations.aopNb}</h3>
                <p>{formatMessage({ id: "card.oil" })}</p>
              </div>
            </div>
          </div>
          {zone.informations.productionPart ?
            <div className="informations-2 bottom-separator">
              <p>{formatMessage({ id: "card.part" })}</p>
              <p>{zone.informations.productionPart}</p>
            </div>
            :
            ""
          }
          <div className="informations-3">
            <h3>{formatMessage({ id: "card.aop.list" })}</h3>
            <ul className="aop-list">
              {zone.informations.aopNames.map((i, id) => {
                return <li key={`${i}-${id}`}>{i}</li>;
              })}
            </ul>
          </div>
        </div>
      :
        <div
          className="card provence-card"
          style={{ top: props.top, left: props.left }}
        >
          <img src={CloseBtn} alt="close" id="close" onClick={closeCard} />
          <div className="card-title bottom-separator">
            <img src={LogoAOP} alt="Logo AOP" />
            <h2>
              {zone.name[locale]}
              <br />
              {formatMessage({ id: "card.since" })} {zone.informations.aopSince}
            </h2>
          </div>

          {zone.informations.olivierNb ||
          zone.informations.olivierHa ||
          zone.informations.production ? (
            <div className="informations-1 bottom-separator">
              {zone.informations.olivierNb || zone.informations.olivierHa ? (
                <div>
                  <img src={Arbre} alt="olivier" />
                  <div>
                    <p>
                      {zone.informations.olivierNb
                        ? zone.informations.olivierNb
                        : ""}
                    </p>
                    <p>
                      {zone.informations.olivierHa !== null
                        ? zone.informations.olivierHa + " ha"
                        : ""}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {zone.informations.production ? (
                <div>
                  <img src={Goutte} alt="goutte" className="goutte" />
                  <div>
                    <h3>{zone.informations.production} t</h3>
                    <p>{formatMessage({ id: "card.prod.aop" })}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {zone.informations.varieties ? (
            <div className="informations-2 bottom-separator">
              <h3>{formatMessage({ id: "card.varieties" })}</h3>
              <div>
                <img src={Olives} alt="olives" />
                <p>{zone.informations.varieties}</p>
              </div>
            </div>
          ) : (
            ""
          )}

          {zone.informations.moulinNb ||
          zone.informations.oleiculteurNb ||
          zone.informations.communeNb ? (
            <div className="informations-3 bottom-separator">
              {zone.informations.moulinNb ? (
                <div>
                  <img src={Moulin} alt="moulin" />
                  <p className="number">{zone.informations.moulinNb}</p>
                  <p>{formatMessage({ id: "card.mills" })}</p>
                </div>
              ) : (
                ""
              )}
              {zone.informations.oleiculteurNb ? (
                <div>
                  <img
                    src={Oleiculteurs}
                    alt="oleiculteurs"
                    className="oleiculteur"
                  />
                  <p className="number">{zone.informations.oleiculteurNb}</p>
                  <p>{formatMessage({ id: "card.olive.growers" })}</p>
                </div>
              ) : (
                ""
              )}
              {zone.informations.communeNb ? (
                <div>
                  <img src={Communes} alt="communes" />
                  <p className="number">{zone.informations.communeNb}</p>
                  <p>{formatMessage({ id: "card.municipalities" })}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {zone.informations.typologie ? (
            <div className="informations-4">
              <h3>{formatMessage({ id: "card.typology" })}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: zone.informations.typologie[locale],
                }}
              ></p>
            </div>
          ) : (
            ""
          )}
        </div>
      }
    </div>
  );

}
