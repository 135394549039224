import React from "react";
import logoAOPFr from "../../assets/images/logo_aop_fr.svg";
import logoAOPEn from "../../assets/images/logo_aop_en.png";
import * as DataManager from "../../modules/datamanager/";

export function Banner(props) {
  const formatMessage = props.formatMessage;
  const carte = DataManager.getCarteById(props.carteId);
  const logo = carte.id === 1 ? props.locale === 'fr' ? logoAOPFr : logoAOPEn : logoAOPFr;

  return (
    <div className="banner">
      <img src={logo} alt="logo AOP" />
      <h1>
        {formatMessage({ id: `banner.text${carte.id}` }, {oilNb: carte.oilNb})}
      </h1>
    </div>
  );
}
