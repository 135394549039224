import React from "react";
import styled from "styled-components";
import * as DataManager from "../../modules/datamanager/";
import { loadMedia } from "../../modules/utils";
import { Link } from "react-router-dom";
import { Card } from "../Card/Card";
import Svg from "../Svg/Svg";

const StyledSvgLink = styled(Link)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  clip-path: url(#${(props) => props.hitzone});
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  background-image: url(${(props) => props.overlay});
  opacity: 0.5;
  z-index: ${(props) => props.zindex};
  &.overlay,
  &:hover,
  &.hover {
    opacity: 1;
  }
  &.disable {
    background: url(${(props) => props.disable});
    cursor: not-allowed;
  }
`;

const StyledSvgFlag = styled(Link)`
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;

export function Zones(props) {

  //Récupération des propriétés
  const locale = props.locale;
  const formatMessage = props.formatMessage;
  const carteId = props.carteId;
  const currentCard = props.currentCard;
  const setCurrentCard = props.setCurrentCard;
  const currentZone = props.currentZone;
  const setCurrentZone = props.setCurrentZone;
  const closeCard = props.closeCard;

  //Récupération de la carte et de ses zones
  const carte = DataManager.getCarteById(carteId);
  const zones = DataManager.getZonesByCarte(carteId);


  /**
   * @summary Trace une ligne entre la zone sélectionnée et sa fiche descriptive 
   * @param {array} start
   * @param {array} end
   */
  function drawLine(start, end, zIndex) {
    
    const canvas = document.querySelector('#line-container');
    const container = document.querySelector('.zones');
    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;
    canvas.style.zIndex = zIndex;
    
    if (!canvas.getContext) {
      return;
    }

    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.moveTo(start[1], start[0]);
    ctx.lineTo(end[1], end[0]);
    ctx.stroke();
  }

  /**
   * @summary Au clic sur une zone : 
   * Toutes les zones (et leurs drapeaux) passe 'disable';
   * La zone sélectionner (et son drapeau) passe 'overlay';
   * La fiche descriptive de la zone sélectionnée apparaît;
   * Une ligne est tracé entre la zone (ou le drapeau) et sa fiche descriptive (=>fonction drawLine)
   */
  function onZoneClick(e, disable) {

    if (disable)
      return;

    e.preventDefault();

    let targetId = e.target.id.includes("flag") ? e.target.id.replace("flag-","") : e.target.id;
    setCurrentZone(document.getElementsByClassName(targetId));

    let hitzone = document.querySelector("#flag-" + targetId);
    if (!hitzone)
      hitzone = document.querySelector("#" + targetId);

    const hitzoneCenterX = hitzone.offsetTop + hitzone.clientHeight / 2;
    const hitzoneCenterY = hitzone.offsetLeft + hitzone.clientWidth / 2;

    const cardCenterX = parseInt(e.target.dataset.cardpositiontop) + e.target.clientHeight / 2;
    const cardCenterY = parseInt(e.target.dataset.cardpositionleft) + e.target.clientWidth / 2;

    setCurrentCard(
      <Card
        id={"card-" + targetId}
        className={"card " + carte.name["fr"] + "-card"}
        carteId={carteId}
        zoneId={targetId.replace("svg-link-", "")}
        top={e.target.dataset.cardpositiontop}
        left={e.target.dataset.cardpositionleft}
        formatMessage={formatMessage}
        locale={locale}
        closeHandler={closeCard}
      />
    );

    drawLine(
      [cardCenterX, cardCenterY],
      [hitzoneCenterX, hitzoneCenterY],
      hitzone.dataset.zindex
    );
  }

  return (
    <div className="zones">
      {zones.map((zone, id) => {
        const svgLinkId = "svg-link-" + zone.id;
        let to = "#";
        let disable =
          currentZone !== null
            ? currentZone[0].className.replace("zone ", "") !== svgLinkId
              ? true
              : false
            : false;
        let overlay =
          currentZone !== null
            ? currentZone[0].className.replace("zone ", "") === svgLinkId
              ? true
              : false
            : false;

        return (
          <div
            key={`${zone.name[locale]}-${id}`}
            className={"zone " + svgLinkId}
          >
            <StyledSvgLink
              id={svgLinkId}
              className={
                disable
                  ? "svg-link disable"
                  : overlay
                  ? "svg-link overlay"
                  : "svg-link"
              }
              width={zone.position.width}
              height={zone.position.height}
              hitzone={zone.hitzone.replace(".svg", "")}
              zoneid={"zone-" + zone.id}
              top={zone.position.top}
              left={zone.position.left}
              zindex={zone.position.zIndex}
              overlay={loadMedia(zone.overlay)}
              disable={loadMedia(zone.disable)}
              onClick={(e) => onZoneClick(e, disable)}
              onPointerEnter={() => {
                if (!disable && carte.id === 1)
                  document
                    .getElementById("flag-" + svgLinkId)
                    .classList.add("hover");
              }}
              onPointerLeave={() => {
                if (!disable && carte.id === 1)
                  document
                    .getElementById("flag-" + svgLinkId)
                    .classList.remove("hover");
              }}
              data-zindex={zone.position.zIndex}
              data-cardpositiontop={zone.card_position.top}
              data-cardpositionleft={zone.card_position.left}
              to={to}
            ></StyledSvgLink>
            <Svg path={loadMedia(zone.hitzone)} />
            {carte.id === 1 ? (
              <StyledSvgFlag
                id={"flag-" + svgLinkId}
                className={
                  disable
                    ? "svg-link disable flag"
                    : overlay
                    ? "svg-link flag overlay"
                    : "svg-link flag"
                }
                zoneid={"zone-" + zone.id}
                top={zone.flag_position.top}
                left={zone.flag_position.left}
                onPointerEnter={() => {
                  if (!disable)
                    document.getElementById(svgLinkId).classList.add("hover");
                }}
                onPointerLeave={() => {
                  if (!disable)
                    document
                      .getElementById(svgLinkId)
                      .classList.remove("hover");
                }}
                onClick={(e) => onZoneClick(e, disable)}
                data-zindex={zone.position.zIndex}
                data-cardpositionleft={zone.card_position.left}
                data-cardpositiontop={zone.card_position.top}
                to={to}
              />
            ) : (
              ""
            )}
          </div>
        );
      })}
      <canvas id="line-container"></canvas>
      {currentCard}
      <div className="bg-zones">
        <img src={loadMedia(carte.background)} alt={carte.name[locale]} />
      </div>
    </div>
  );
}
