import React, { useState } from "react";
import { Zones } from "../Zones/Zones";
import { useParams, generatePath } from "react-router";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import * as DataManager from "../../modules/datamanager/";
import { AppRoute } from "../../const";
import { Banner } from "../Banner/Banner";
import LoupePlus from "../../assets/images/loupe_plus.svg";
import LoupeMoins from "../../assets/images/loupe_moins.svg";

/**
 * @summary Composant conteneur de la carte. Affiche les composants : Banner, Zones, La loupe qui permet de changer de carte.
 * @returns
 */
export function Map() {
  
  const { formatMessage, locale } = useIntl();

  //Récupération des données grâce aux paramètres présent dans la requête
  const params = useParams();
  const id = params.name === "provence" ? 2 : 1;
  const carte = DataManager.getCarteById(id);

  //
  const [currentCard, setCurrentCard] = useState();
  const [currentZone, setCurrentZone] = useState(null);

  /**
   * @summary Ferme la fiche descriptive ouverte, enlève l'overlay de la zone sélectionné et efface la ligne.
   * @returns 
   */
  function closeCard() {
    setCurrentZone(null);
    setCurrentCard(null);
    const canvas = document.getElementById("line-container");
    canvas.style.zIndex = 0;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0,0, canvas.width, canvas.height);
  }

  //Traitement des données
  const otherCarte =
    carte.id === 1 ? DataManager.getCarteById(2) : DataManager.getCarteById(1);
  const loupe = (
    <img
      src={otherCarte.id === 2 ? LoupePlus : LoupeMoins}
      alt={otherCarte.name[locale]}
      width="120px"
      onClick={closeCard}
    />
  );

  return (
    <div className="map-container" id={carte.name["fr"]}>
      <Banner carteId={carte.id} formatMessage={formatMessage} locale={locale} />
      <Zones
        carteId={carte.id}
        formatMessage={formatMessage}
        locale={locale}
        currentCard={currentCard}
        setCurrentCard={setCurrentCard}
        currentZone={currentZone}
        setCurrentZone={setCurrentZone}
        closeCard={closeCard}
      />
      <Link
        to={generatePath(AppRoute.Carte, {
          name: otherCarte.name["fr"].toLowerCase(),
        })}
        title={otherCarte.name[locale]}
        id="loupe" className={otherCarte.name["fr"].toLowerCase()}
      >
        {loupe}
      </Link>
    </div>
  );
}
