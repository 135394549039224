import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Footer } from "./Footer/Footer";
import { Map } from "./Map/Map";
import { AppRoute, AppDefaultLanguage } from "../const";
import { locales, translations } from "../modules/i18n/i18n";


function App() {
  
  let appDivRef = useRef();
  let location = useLocation();
  let query = useQuery();

  const [locale, setLocale] = useState(AppDefaultLanguage);

  const [scale, setScale] = useState({
    transform: {
      scale: 1,
      topPosition: "0px",
      leftPosition: "0px",
    },
  });

  useEffect(() => {
    initLocale();
    window.addEventListener("resize", scaleApp);
    return () => {
      window.removeEventListener("resize", scaleApp);
    };
  }, []);

  useEffect(() => {
    scaleApp();
  }, [appDivRef]);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function initLocale() {
    const queryLocale = query.get("locale");
    if (queryLocale && queryLocale !== locale) {
      setLocale(locales.includes(queryLocale) ? queryLocale : AppDefaultLanguage);
    }
  }

  function scaleApp() {
    if (!appDivRef.current)
      return;
    var body = document.body;
    const screenWidth = body.offsetWidth !== 0 ? body.offsetWidth : window.innerWidth;
    const screenHeight = body.offsetHeight !== 0 ? body.offsetHeight : window.innerHeight;
    const scaleX = screenWidth / appDivRef.current.clientWidth;
    const scaleY = screenHeight / appDivRef.current.clientHeight;
    const scale = Math.min(scaleX, scaleY);
    const scaledHeight = appDivRef.current.clientHeight * scale;
    const scaledWidth = appDivRef.current.clientWidth * scale;
    const topPosition = (screenHeight - scaledHeight) / 2;
    const leftPosition = (screenWidth - scaledWidth) / 2;
    setScale({
      transform: {
        scale: scale,
        topPosition: topPosition > 0 ? `${topPosition}px` : "0px",
        leftPosition: leftPosition > 0 ? `${leftPosition}px` : "0px",
      },
    });
  }

  return (
    <div id="app" style={
      {
        transform: `scale(${scale.transform.scale})`,
        transformOrigin: "0px 0px",
        position: "absolute",
        top: scale.transform.topPosition,
        left: scale.transform.leftPosition,
      }
    } ref={appDivRef}>
      <IntlProvider locale={locale} messages={translations[locale]}>
        <Routes location={location}>
          <Route path={AppRoute.Carte} element={<Map />} />
          <Route path={AppRoute.Default} element={<Map />} />
        </Routes>
        <Footer />
      </IntlProvider>
    </div>
  );
}

export default App;