import JSPath from "jspath";

import app from "../../assets/france-olive.json";

export const getCartes = () => {
  const cartes = JSPath.apply(".cartes", app);
  return cartes;
};

export const getCarteById = (id) => {
  const res = JSPath.apply(".cartes{.id == " + id + "}", app);
  const carte = res.length === 1 ? res[0] : null;
  return carte;
};

export const getZonesByCarte = (id) => {
  const zones = JSPath.apply(".cartes{.id == " + id + "}.zones", app);
  return zones;
};

export const getZoneById = (id) => {
  const res = JSPath.apply(".cartes.zones{.id == " + id + "}", app);
  const zone = res.length === 1 ? res[0] : null;
  return zone;
};