import { fr , en } from './localizations';

// Array of supported locales
// The first in the array is treated as the default locale
export const locales = ['fr', 'en'];

// Dictionary of translations
export const translations = { fr , en }

export const I18n = [
  locales,
  translations,
];
