export const en = {
  
  "banner.text1": "More than {oilNb} PDO olive oils",
  "banner.text2": "{oilNb} AOP(PDO) olive oils",

  "card.since": "AOP(PDO) SINCE",
  "card.prod.aop": "Production of PDO oil",
  "card.varieties": "Main varieties for PDO olive oil",
  "card.mills": "Mills",
  "card.municipalities": "Municipalities",
  "card.olive.growers": "Olive growers",
  "card.typology": "Olive oil typology",

  "card.orchards": "Olive tree orchards",
  "card.prod.national": "Average national production",
  "card.oil": "PDO oils",
  "card.part": "Ratio of PDO production to total domestic production",
  "card.aop.list": "List of PDO",
};
