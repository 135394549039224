import React from "react";
import logoEu from "../../assets/images/logo_eu.svg";
import logoEnjoyFromEu from "../../assets/images/logo_enjoy_from_eu.svg";
import logoPerfectFood from "../../assets/images/logo_perfect_food.svg";
import logoFranceOlive from "../../assets/images/logo_france_olive.webp";


export function Footer() {

  return (
    <footer className="footer">
      <a
        href="https://european-union.europa.eu/index_fr"
        target="_blank"
        title="Site de l'union européenne"
        rel="noreferrer"
        className="logo"
      >
        <img src={logoEu} alt="" width="240"/>
      </a>
      <a
        href="https://afidol.org/"
        target="_blank"
        title="Site de France Olive"
        rel="noreferrer"
        className="logo"
      >
        <img src={logoFranceOlive} alt="" width="68"/>
      </a>
      <a
        href="https://www.lookingfortheperfectfood.eu/fr/"
        target="_blank"
        title="looking for the perfect food"
        rel="noreferrer"
        className="logo"
      >
        <img src={logoPerfectFood} alt="" width="84"/>
      </a>
      <a
        href="https://ec.europa.eu/chafea/agri/fr/node/115"
        target="_blank"
        title="Promotion of agricultural products"
        rel="noreferrer"
        className="logo"
      >
        <img src={logoEnjoyFromEu} alt="" width="78"/>
      </a>
    </footer>
  );
}
